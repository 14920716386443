import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Module from '../components/Module'

const locale = process.env.GATSBY_LOCALE

export const query = graphql`
  query($id: String) {
    page: sanityTutorial(id: { eq: $id }) {
      id
      title {
        enUS
        svSE
      }
      description {
        enUS
        svSE
      }
      mainImage {
        asset {
          localFile {
            publicURL
          }
        }
      }
      video {
        _key
        url
      }
      _rawContent
    }
  }
`

const IndexPage = ({ data: { page }, location: { pathname } }) => {
  const [title, extraTitle] = page.title[locale].split('  ')
  const content =
    page.video && page.video.url
      ? [
          { _key: page.video._key, _type: 'youtube', url: page.video.url },
          ...page._rawContent[locale],
        ]
      : page._rawContent[locale]
  return (
    <Layout>
      <SEO
        title={[title, extraTitle].join(' ')}
        description={page.description[locale]}
        image={page.mainImage.asset.localFile.publicURL}
        path={pathname}
      />
      <Module
        module={{
          title: { [locale]: title + ' ' },
          extraTitle: { [locale]: extraTitle },
          body: { _type: 'intlContent', [locale]: content },
          align: 'left',
          first: true,
          type: 'white',
        }}
      />
    </Layout>
  )
}

export default IndexPage
